<template>
  <div class="courtyardOrder">
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      :header-cell-style="{
        'background-color': '#eee',
        color: '#333',
        fontSize: '16px',
      }"
    >
      <el-table-column prop="phone" label="联系方式" />
      <el-table-column prop="name" label="姓名" />
      <el-table-column
        prop="address"
        label="房屋所在省/市/区(县)"
        width="200"
      />
      <el-table-column prop="type" label="四合院加盟" />
      <el-table-column prop="create_time" label="时间" width="180" />
      <el-table-column
        prop="is_shy_register"
        label="是否四合院注册"
        width="180"
      >
        <template slot-scope="scope"
          ><span>{{ scope.row.is_shy_register ? '是' : '否' }}</span></template
        >
      </el-table-column>
      <el-table-column prop="order_info" label="订单信息" width="180">
        <template slot-scope="scope"
          ><span
            style="color: #6376f7; cursor: pointer"
            @click="showOrderInfo(scope.row.order_info)"
            >{{ scope.row.order_info ? '订单信息' : '--' }}</span
          ></template
        >
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="dialogVisible" title="订单详情" destroy-on-close>
      <div
        class="order_info_list"
        v-for="(item, index) in currentOderInfo"
        :key="index"
      >
        <div style="width: 30%">{{ item.name }}</div>
        <div style="width: 30%">{{ item.price }}</div>
        <div><img :src="item.image ? item.image : ''" alt="" srcset="" /></div>
      </div>
    </el-dialog>
    <div class="pagination">
      <el-pagination
        :page-size="12"
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="form.page"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      form: {
        page: 1,
        limit: 12,
      },
      total: 0,
      currentOderInfo: [],
    }
  },
  methods: {
    showOrderInfo(data) {
      console.log(data)
      if (data) {
        this.dialogVisible = true
        this.currentOderInfo = data
      } else {
        this.currentOderInfo = []
      }
    },
    handleCurrentChange(page) {
      this.form.page = page
      this.getList()
    },
    getList() {
      this.$request.seeyardAdminOrder(this.form, (res) => {
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
  },
  mounted() {
    this.getList()
  },
}
</script>

<style lang="less" scoped>
.courtyardOrder {
  padding: 0 32px;
  .order_info_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
  }
}
</style>
